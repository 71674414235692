/*
 * @Author: your name
 * @Date: 2021-06-04 18:20:13
 * @LastEditTime: 2021-07-01 14:51:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qianyue\src\components\Disease\Disease.js
 */
let Disease = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 获取疾病列表数据
     * @param {any} success
     * @param {any} error
     */
    this.Diseases = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Diseases/Search/' + params;
        TokenClient.Get(url, false, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    
    /**
     * 症状查询获取疾病列表数据
     * @param {*} isClinical 
     * @param {*} category 
     * @param {*} pageIndex 
     * @param {*} department 
     * @param {*} keyword 
     * @param {*} success 
     * @param {*} error 
     */
    this.Clinicals = function (isClinical,category,pageIndex,department,keyword, success, error) {
        var url = ServiceBaseAddress + '/api/Diseases/Search';
        if (isClinical || isClinical==0) {
            url += `/${isClinical}`;
        }
        if (category) {
            url += `/${category}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (department || department==0) {
            url += `/${department}`;
        }
        if (keyword) {
            url += `/${keyword}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, false, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 
     * @param {*} category 
     * @param {*} success 
     * @param {*} error 
     */
    this.DiseaseDepartmentList = function (category, success, error) {
        var url = ServiceBaseAddress + '/api/Diseases/Categories';
        if (category) {
            url += `/${category}`;
        }
        TokenClient.Get(url, false, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取疾病详情数据
     * @param {any} success
     * @param {any} error
     */
    this.Detail = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Diseases/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Disease }